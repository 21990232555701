export default {
  usdt: {
    name: 'ビットコイン／ドル',
    icon: 'icon-btc',
    path: '2',
  },
  btc: {
    name: 'ビットコイン円',
    icon: 'icon-btc',
    path: '2',
  },
  cms: {
    name: 'コムサ円',
    icon: 'icon-cms',
    path: '0',
  },
  cbn: {
    name: 'c0ban円',
    icon: 'icon-bitcion',
    path: '0',
  },
  eth: {
    name: 'イーサリアム円',
    icon: 'icon-eth',
    path: '6',
  },
  xrp: {
    name: 'リップル円',
    icon: 'icon-xrp',
    path: '0',
  },
  ltc: {
    name: 'ライトコイン円',
    icon: 'icon-ltc',
    path: '0',
  },
  bch: {
    name: 'ビットコインキャッシュ円',
    icon: 'icon-bch',
    path: '2',
  },
  btg: {
    name: 'ビットコインコイン ゴールド円',
    icon: 'icon-btg',
    path: '3',
  },
  xem: {
    name: 'ネム円',
    icon: 'icon-xem',
    path: '4',
  },
  etc: {
    name: 'イーサリアムクラシック円',
    icon: 'icon-etc',
    path: '0',
  },
  strat: {
    name: 'ストラティス円',
    icon: 'icon-strat',
    path: '0',
  },
  neo: {
    name: 'ネオ円',
    icon: 'icon-neo',
    path: '0',
  },
  sc: {
    name: 'シアコイン円',
    icon: 'icon-sc',
    path: '0',
  },
  fct: {
    name: 'ファクトム円',
    icon: 'icon-fct',
    path: '33',
  },
  lsk: {
    name: 'リスク円',
    icon: 'icon-lsk',
    path: '10',
  },
  game: {
    name: 'ゲームクレジット円',
    icon: 'icon-game',
    path: '0',
  },
  mona: {
    name: 'モナー円',
    icon: 'icon-mona',
    path: '0',
  },
  qtum: {
    name: 'クアンタム円',
    icon: 'icon-qtum',
    path: '0',
  },
  qash: {
    name: 'キャッシュ円',
    icon: 'icon-qash',
    path: '0',
  },
  pay: {
    name: 'テンエックス円',
    icon: 'icon-pay',
    path: '0',
  },
  bat: {
    name: 'ベーシックアテンショントークン円',
    icon: 'icon-bat',
    path: '3',
  },
  omg: {
    name: 'オミセゴー円',
    icon: 'icon-omg',
    path: '1',
  },
  ark: {
    name: 'アーク円',
    icon: 'icon-ark',
    path: '0',
  },
  bnb: {
    name: 'バイナンスコイン',
    icon: 'icon-bnb',
    path: '0',
  },
  bts: {
    name: 'ビットシェアーズ',
    icon: 'icon-bts',
    path: '0',
  },
  ada: {
    name: 'カルダノ',
    icon: 'icon-ada',
    path: '0',
  },
  link: {
    name: 'チェーンリンク',
    icon: 'icon-link',
    path: '28',
  },
  doge: {
    name: 'ドージコイン',
    icon: 'icon-doge',
    path: '124',
  },
  eos: {
    name: 'イオス',
    icon: 'icon-eos',
    path: '2',
  },
  gas: {
    name: 'ガス',
    icon: 'icon-gas',
    path: '2',
  },
  iota: {
    name: 'アイオータ',
    icon: 'icon-iota',
    path: '0',
  },
  mkr: {
    name: 'メイカー',
    icon: 'icon-mkr',
    path: '2',
  },
  salt: {
    name: 'ソルト',
    icon: 'icon-icon-salt',
    path: '20',
  },
  xlm: {
    name: 'ステラルーメン',
    icon: 'icon-xlm',
    path: '1',
  },
  storm: {
    name: 'ストーム',
    icon: 'icon-storm',
    path: '3',
  },
  trx: {
    name: 'トロン',
    icon: 'icon-trx',
    path: '1',
  },
  ven: {
    name: 'ヴィチェーン',
    icon: 'icon-ven',
    path: '31',
  },
  wtc: {
    name: 'ウォルトンチェーン',
    icon: 'icon-wtc',
    path: '0',
  },
  wan: {
    name: 'ワンチェーン',
    icon: 'icon-wan',
    path: '4',
  },
  etn: {
    name: 'エレクトロニウム',
    icon: 'icon-etn',
    path: '5',
  },
  bnt: {
    name: 'バンコール',
    icon: 'icon-bnt',
    path: '2',
  },
  maid: {
    name: 'メイドセーフコイン',
    icon: 'icon-maid',
    path: '3',
  },
  ht: {
    name: 'フォビトークン',
    icon: 'icon-ht',
    path: '2',
  },
  drgn: {
    name: 'ドラゴンチェーン',
    icon: 'icon-drgn',
    path: '2',
  },
  kcs: {
    name: 'クーコインシェアーズ',
    icon: 'icon-kcs',
    path: '0',
  },
  dent: {
    name: 'デント',
    icon: 'icon-dent',
    path: '0',
  },
  okb: {
    name: 'オーケービー',
    icon: 'icon-okb',
    path: '8',
  },
  vet: {
    name: 'ヴィチェーンソール',
    icon: 'icon-ven',
    path: '31',
  },
  bsv: {
    name: 'ビットコインSV',
    icon: 'icon-bsv',
    path: '3',
  },
  xtz: {
    name: 'テゾス',
    icon: 'icon-xtz',
    path: '0',
  },
  atom: {
    name: 'コスモス',
    icon: 'icon-atm',
    path: '9',
  },
  zrx: {
    name: 'ゼロエックス',
    icon: 'icon-zrx',
    path: '0',
  },
  iost: {
    name: 'アイオーエスティー',
    icon: 'icon-iost',
    path: '0',
  },
  theta: {
    name: 'シータ',
    icon: 'icon-theta-token1',
    path: '0',
  },
  matic: {
    name: 'マティック',
    icon: 'icon-matic',
    path: '57',
  },
  beam: {
    name: 'ビーム',
    icon: 'icon-beam',
    path: '8',
  },
  dot: {
    name: 'ポルカドット',
    icon: 'icon-dot',
    path: '1',
  },
  fil: {
    name: 'ファイルコイン',
    icon: 'icon-fil',
    path: '2',
  },
  dai: {
    name: 'ダイ',
    icon: 'icon-dai',
    path: '2',
  },
  uni: {
    name: 'ユニスワップ',
    icon: 'icon-uni',
    path: '1',
  },
  comp: {
    name: 'コンパウンド',
    icon: 'icon-comp',
    path: '2',
  },
  enj: {
    name: 'エンジンコイン',
    icon: 'icon-enj',
    path: '3',
  },
  wbtc: {
    name: 'ラップドビットコイン',
    icon: 'icon-wbtc',
    path: '6',
  },
  aave: {
    name: 'アーベ',
    icon: 'icon-aave',
    path: '10',
  },
  ftt: {
    name: 'エフティ―エックストークン',
    icon: 'icon-ftt',
    path: '4',
  },
  sol: {
    name: 'ソラナ',
    icon: 'icon-sol',
    path: '15',
  },
  sushi: {
    name: 'スシスワップ',
    icon: 'icon-sushi',
    path: '13',
  },
  avax: {
    name: 'アバランチ',
    icon: 'icon-avax',
    path: '2',
  },
  cake: {
    name: 'パンケーキスワップ',
    icon: 'icon-cake',
    path: '19',
  },
  hbar: {
    name: 'ヘデラハッシュグラフ',
    icon: 'icon-hbar',
    path: '2',
  },
  chz: {
    name: 'チリーズ',
    icon: 'icon-chz',
    path: '0',
  },
  icp: {
    name: 'インターネットコンピューター',
    icon: 'icon-icp',
    path: '14',
  },
  luna: {
    name: 'テラ',
    icon: 'icon-luna',
    path: '4',
  },
  algo: {
    name: 'アルゴランド',
    icon: 'icon-algo',
    path: '1',
  },
  mana: {
    name: 'ディセントラランド',
    icon: 'icon-mana',
    path: '200',
  },
  xym: {
    name: 'シンボル',
    icon: 'icon-xym',
    path: '103',
  },
  jasmy: {
    name: 'ジャスミー',
    icon: 'icon-jasmy',
    path: '4',
  },
  busd: {
    name: 'バイナンスユーエスディー',
    icon: 'icon-busd',
    path: '1',
  },
  shib: {
    name: 'シバイヌ',
    icon: 'icon-shib',
    path: '16',
  },
  usdc: {
    name: 'ユーエスディーコイン',
    icon: 'icon-usdc',
    path: '3',
  },
  dep: {
    name: 'ディープコイン',
    icon: 'icon-dep',
    path: '8',
  },
  plt: {
    name: 'パレットトークン',
    icon: 'icon-plt',
    path: '7',
  },
  boba: {
    name: 'ボバネットワーク',
    icon: 'icon-boba',
    path: '191',
  },
  sand: {
    name: 'サンド',
    icon: 'icon-sand',
    path: '2',
  },
  gmt: {
    name: 'ジーエムティー',
    icon: 'icon-gmt',
    path: '28',
  },
  klay: {
    name: 'クレイトン',
    icon: 'icon-klay',
    path: '4',
  },
  astr: {
    name: 'アスター',
    icon: 'icon-astar',
    path: '200',
  },
  flr: {
    name: 'フレア',
    icon: 'icon-flr',
    path: '4',
  },
  axs: {
    name: 'アクシーインフィニティ',
    icon: 'icon-axs',
    path: '2',
  },
  grt: {
    name: 'ザ・グラフ',
    icon: 'icon-grt',
    path: '2',
  },
}
